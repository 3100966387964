import { computed, Ref } from 'vue'
import { useRequest } from 'vue-request'

import { getSurveyCPTs } from '@/services/api/cpt'
import { getSurveyAnalysisResponses, getSurveyResponses } from '@/services/api/response'
import { getSurvey } from '@/services/api/survey'

export default function useSurveyPrep(surveyId: Ref<string>, type: number): any {
  const { runAsync: runSurvey, data: dataSurvey } = useRequest(getSurvey, {
    manual: true
  })
  const currentSurvey = computed(() => dataSurvey.value)

  const { runAsync: runSurveyCPTs, data: dataSurveyCPTs } = useRequest(getSurveyCPTs, {
    manual: true
  })
  const persistedCpts = computed(() => dataSurveyCPTs.value?.content || [])

  const { runAsync: runSurveyResponses, data: dataSurveyResponses } = useRequest(
    type == 0 ? getSurveyResponses : getSurveyAnalysisResponses,
    {
      manual: true
    }
  )
  const persistedResponses = computed(() => dataSurveyResponses.value?.content || [])

  // Turn on later when necessary
  //
  // await store.dispatch(vuexActions(SURVEY_SUMMARY, SurveySummaryActionEnum.GET_SURVEY_SUMMARIES), {
  //   surveyId
  // })
  // await store.dispatch(vuexActions(SURVEY_STATUS, SurveyStatusActionEnum.GET_SURVEY_STATUSES), {
  //   surveyId,
  //   getAll: true
  // })

  const prepareAll = async () => {
    if (surveyId.value) {
      await runSurvey(surveyId.value)
      await runSurveyCPTs({
        surveyId: surveyId.value,
        getAll: true
      })
      await runSurveyResponses({
        surveyId: surveyId.value
      })
    }
  }

  const reloadResponses = () => {
    runSurveyResponses({
      surveyId: surveyId.value
    })
  }

  return {
    reloadResponses,
    currentSurvey,
    persistedCpts,
    persistedResponses,
    prepareAll
  }
}
