<template>
  <CauseEffectWorkspace />
</template>

<script lang="ts">
import { message } from 'ant-design-vue'
import { computed, defineComponent } from 'vue'
import { NavigationGuard, NavigationGuardWithThis, useRoute } from 'vue-router'

import CauseEffectWorkspace from '@/components/analysis/cause-effect/CauseEffectWorkspace.vue'
import { MESSAGE } from '@/constants/message'
import { PATH } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { isInvalidRouterParamId, normalizeRouterParamId } from '@/libs/utils'
import { useStore } from '@/store'
import { AllocationActionEnum } from '@/store/enums/actions/allocation'
import { NetworkActionEnum } from '@/store/enums/actions/network'
import { UserActionEnum } from '@/store/enums/actions/user'
import { vuexActions } from '@/store/util'
import { Allocation } from '@/types'

const { NETWORK, ALLOCATION } = ModuleNames

const guard: NavigationGuardWithThis<undefined> | NavigationGuard = async (to, from, next) => {
  let { workspaceId } = to.params
  let errorMessage
  if (isInvalidRouterParamId(workspaceId)) {
    errorMessage = MESSAGE.UNEXPECTED_WORKSPACE_ID(workspaceId)
  }
  if (errorMessage) {
    message.error({
      content: errorMessage,
      duration: 3
    })
    setTimeout(() => {
      next(PATH.HOME)
    }, 3000)
    return
  }
  workspaceId = workspaceId as string
  const store = useStore()
  await store.dispatch(vuexActions(NETWORK, NetworkActionEnum.GET_NETWORKS), workspaceId)
  const allocations = (
    await store.dispatch(vuexActions(ALLOCATION, AllocationActionEnum.GET_ALLOCATIONS), workspaceId)
  ).content
  const userIds = allocations.map(({ userId }: Pick<Allocation, 'userId'>) => userId)
  await store.dispatch(vuexActions(ModuleNames.USER, UserActionEnum.GET_USERS), {
    params: { id: userIds },
    getAll: true
  })
  next()
}

export default defineComponent({
  components: {
    CauseEffectWorkspace
  },
  beforeRouteEnter: guard as NavigationGuard,
  beforeRouteUpdate: guard as NavigationGuardWithThis<undefined>,
  setup() {
    const route = useRoute()
    const workspaceId = computed(() => normalizeRouterParamId(route.params.workspaceId))
    return {
      workspaceId
    }
  }
})
</script>

<style scoped></style>
