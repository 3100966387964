import { render } from "./SurveySelection.vue?vue&type=template&id=deebebe6"
import script from "./SurveySelection.vue?vue&type=script&lang=ts"
export * from "./SurveySelection.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "deebebe6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('deebebe6', script)) {
    api.reload('deebebe6', script)
  }
  
  module.hot.accept("./SurveySelection.vue?vue&type=template&id=deebebe6", () => {
    api.rerender('deebebe6', render)
  })

}

script.__file = "src/components/survey/SurveySelection.vue"

export default script