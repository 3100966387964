<template>
  <div class="sz-survey-selection">
    <a-table
      :data-source="surveyList"
      size="middle"
      class="sz-core-content"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onSelect: onSelect
      }"
    >
      <a-table-column :key="DB_FIELDS.SURVEY.NAME" title="Name" :data-index="DB_FIELDS.SURVEY.NAME">
        <template #default="{ record }">
          <router-link
            :to="{ name: ROUTE_NAME.SURVEY, params: { workspaceId, surveyId: record.id } }"
          >
            {{ record.name }}
          </router-link>
        </template>
      </a-table-column>
      <a-table-column
        :key="DB_FIELDS.SURVEY.STATE"
        title="Status"
        :data-index="DB_FIELDS.SURVEY.STATE"
      />
      <a-table-column
        :key="DB_FIELDS.SURVEY.COLLECTION_METHOD"
        title="Collection Method"
        :data-index="DB_FIELDS.SURVEY.COLLECTION_METHOD"
      />
      <a-table-column key="scale.method" title="Input Scale" data-index="scale.method" />
      <a-table-column
        :key="DB_FIELDS.COMMON.CREATED_DATE"
        title="Created at"
        :data-index="DB_FIELDS.COMMON.CREATED_DATE"
      />
    </a-table>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, reactive, toRefs, watch } from 'vue'
import { useRequest } from 'vue-request'
import { useRoute } from 'vue-router'

import { DB_FIELDS } from '@/constants/database'
import { ROUTE_NAME } from '@/constants/router'
import { getSurveys } from '@/services/api/survey'
import { SurveyExportFormatEnum } from '@/types'
import { SurveySchema } from '@/types'

export const EVENTS = {
  SURVEY_SELECTION_CHANGE: 'SURVEY_SELECTION_CHANGE'
}

export default defineComponent({
  props: {
    selectedSurveyIds: { type: Array as PropType<string[]>, default: () => [] },
    multiple: { type: Boolean, default: false }
  },
  emits: Object.values(EVENTS),
  setup(props, { emit }) {
    const state = reactive<{
      selectedRowKeys: string[]
    }>({
      selectedRowKeys: []
    })

    watch(
      () => props.selectedSurveyIds,
      (selectedSurveyIds: string[]) => {
        state.selectedRowKeys = selectedSurveyIds?.length ? selectedSurveyIds : []
      }
    )
    const route = useRoute()
    const workspaceId = computed(() => {
      if (typeof route.params.workspaceId === 'string') {
        return route.params.workspaceId
      }
      return null
    })

    const { run: runGetSurveys, data: dataSurveys } = useRequest(getSurveys, {
      manual: true
    })

    const surveyList = computed(() => {
      const surveys = dataSurveys.value?.content
      return surveys?.map((each: SurveySchema) => ({
        ...each,
        key: each?.id,
        source: each
      }))
    })

    onMounted(() => {
      if (workspaceId.value && typeof workspaceId.value === 'string') {
        runGetSurveys(workspaceId.value)
      }
    })

    const onSelect = (record: any, selected: boolean) => {
      if (selected) {
        state.selectedRowKeys = [record.id]
      } else {
        state.selectedRowKeys = []
      }
      emit(
        EVENTS.SURVEY_SELECTION_CHANGE,
        state.selectedRowKeys,
        selected ? record.source : undefined
      )
      console.log(state.selectedRowKeys)
    }

    return {
      onSelect,
      ...toRefs(state),
      workspaceId,
      ROUTE_NAME,
      DB_FIELDS,
      surveyList,
      SurveyExportFormatEnum
    }
  }
})
</script>
<style></style>
