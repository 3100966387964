
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { computed, defineComponent, PropType, toRef } from 'vue'

import useMethods from '@/components/composables/methods'
import AceTable from '@/components/method-ace/AceTable.vue'
import CainTable from '@/components/method-cain/CainTable.vue'
import { TABLE_EVENTS } from '@/components/method-common/Table'
import MarginalTable from '@/components/method-marginal/MarginalTable.vue'
import { DB_ENUM_VALUES } from '@/constants/database'
import { ROUTE_NAME } from '@/constants/router'
import { CPT, Network, Variable } from '@/libs/bayes'
import { ResponseSchema, SurveySchema } from '@/types'

export const EVENTS = {
  ON_RESPONSES_CHANGE: 'ON_RESPONSES_CHANGE'
}

export default defineComponent({
  components: {
    AceTable,
    MarginalTable,
    CainTable
  },
  props: {
    network: { type: Object as PropType<Network>, required: true },
    variable: { type: Variable, required: true },
    survey: { type: Object as PropType<SurveySchema>, required: true },
    cpt: { type: Object as PropType<CPT>, required: true },
    responses: { type: Object as PropType<ResponseSchema[]>, required: true },
    analyticsMap: { type: Object, required: true },
    userMap: { type: Object, required: true },
    currentUser: { type: Object, required: true },
    hasDiscreteScale: { type: Boolean, required: true },
    isAnonymous: { type: Boolean, required: true },
    editable: { type: Boolean, required: true }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const cpt = toRef(props, 'cpt')

    const {
      isCain,
      isAceOriginal,
      isAceDst,
      isAceDstSimple,
      isAceDstFull,
      isMarginal
    } = useMethods(cpt)

    const onResponsesChange = async ({ responses }: { responses: Array<ResponseSchema> }) => {
      if (props.editable) {
        emit(EVENTS.ON_RESPONSES_CHANGE, responses)
      }
    }

    const rows = computed(() => cpt.value?.elicitedRows || [])

    const selectedVariableResponses = computed(() => {
      if (props.variable && rows.value?.length) {
        return props.analyticsMap?.[props.variable.id]?.aggResponses
      }
      return []
    })

    const selectedAnalytics = computed(() => {
      if (props.variable && rows.value?.length) {
        return props.analyticsMap?.[props.variable.id]
      }
      return []
    })

    const canEdit = computed(() => {
      return true
      // if (!selectedAnalytics.value?.aggResponses?.length) {
      //   return true
      // }
      // const aggResponses = selectedAnalytics.value?.aggResponses
      // if (aggResponses) {
      //   for (let i = 0; i < aggResponses.length; i++) {
      //     const response = aggResponses[i]
      //     if (response.userId !== props.currentUser.id) {
      //       return false
      //     }
      //   }
      // }
      // return true
    })

    return {
      canEdit,
      onResponsesChange,
      DB_ENUM_VALUES,
      selectedAnalytics,
      selectedVariableResponses,
      TABLE_EVENTS,
      rows,
      isCain,
      isAceDst,
      isAceOriginal,
      isAceDstSimple,
      isAceDstFull,
      isMarginal,
      ROUTE_NAME
    }
  }
})
