
import { Table as IconTable } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import { computed, defineComponent, PropType } from 'vue'

import { InputType, ROW_TYPE } from '@/components/analysis/libs/common'
import { Network, Variable } from '@/libs/bayes'
import { genCellBgStyleRange } from '@/libs/theme'
import { cssIcon } from '@/styles/common'

export const EVENTS = {
  ON_SELECT_VARIABLE: 'ON_SELECT_VARIABLE'
}

export default defineComponent({
  components: {
    Icon,
    IconTable
  },
  props: {
    title: { type: String, required: true },
    selectedOutputNode: { type: Object, required: true },
    selectedNetwork: { type: Object as PropType<Network | null>, required: true },
    probMap: { type: Object, default: null }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const columns = computed(() => {
      const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          align: 'left',
          slots: {
            customRender: 'name'
          }
        },
        {
          title: props.title,
          dataIndex: 'value',
          key: 'value',
          align: 'center',
          slots: {
            customRender: 'value'
          }
        }
      ]
      return columns
    })

    const getProb = (key: string) => {
      if (props.probMap && props.probMap[key]) {
        return props.probMap[key]
      }
      return [[]]
    }

    const data = computed(() => {
      if (!props.selectedOutputNode || !props.selectedNetwork) {
        return []
      }

      const net: Network = props.selectedNetwork
      const key = props.selectedOutputNode.key
      const outputVar = net.variableMapByKey[key]
      const name = outputVar.name
      const parents = net.parents[outputVar.id]
      let rows = [
        {
          type: ROW_TYPE.LABEL,
          name: 'Output Node',
          value: '',
          key: 'out-label',
          states: [''],
          variable: outputVar
        }
      ]
      rows.push({
        type: ROW_TYPE.OUTPUT,
        name: name,
        value: getProb(key),
        key: name,
        variable: outputVar,
        states: outputVar.getAllStates().map(({ state }) => state.name)
      })
      rows.push({
        type: ROW_TYPE.LABEL,
        name: 'Parents',
        value: '',
        key: 'parents-label',
        states: [''],
        variable: outputVar
      })
      parents?.forEach((parent: Variable) => {
        const key = parent.key || ''
        rows.push({
          type: ROW_TYPE.INPUT,
          name: parent.name || '',
          key,
          variable: parent,
          value: getProb(key),
          states: parent.getAllStates().map(({ state }) => state.name)
        })
      })
      return rows
    })

    const cellBgStyle = (val: number) => {
      return genCellBgStyleRange(0, 1, val)
    }

    const update = (comp: any) => {
      comp.$forceUpdate()
    }

    const selectVariable = (variable: Variable) => {
      emit(EVENTS.ON_SELECT_VARIABLE, variable)
    }

    return {
      selectVariable,
      update,
      data,
      columns,
      cellBgStyle,
      cssIcon,
      ROW_TYPE,
      EVENTS,
      InputType
    }
  }
})
