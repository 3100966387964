<template>
  <div class="sz-analysis sz-cause-effect">
    <workspace-info :workspace="workspace" />
    <div class="sz-command-bar">
      <a-button type="link" @click="saveJob">
        <template #icon>
          <Icon :class="cssIcon">
            <DeviceFloppy />
          </Icon>
        </template>
        <span>Save current analysis job</span>
      </a-button>
    </div>
    <a-tabs v-model:activeKey="activeTab" tab-position="left">
      <a-tab-pane :key="TABS.PARAMS" tab="Parameters">
        <splitpanes class="default-theme" horizontal>
          <pane size="50">
            <div class="sza-networks-wrapper">
              <div class="heading">
                <div class="title">Surveys</div>
              </div>
              <SurveySelection
                :selected-survey-ids="selectedSurveyIds"
                @[SURVEY_EVENTS.SURVEY_SELECTION_CHANGE]="surveySelectionChange"
              />
            </div>
          </pane>
          <pane>
            <div class="sza-node-selections-wrapper">
              <div class="title">All Variables</div>
              <div v-if="commonNodeKeys.length == 0 && networks.length > 1" class="warning">
                No variables common in all networks
              </div>
              <cause-effect-node-selection
                :node-selections="nodeSelections"
                :config="selectionConfig"
                @[VAR_EVENTS.TOGGLE_INPUT]="toggleInput"
                @[VAR_EVENTS.TOGGLE_OUTPUT]="toggleOutput"
              />
            </div>
          </pane>
        </splitpanes>
      </a-tab-pane>
      <a-tab-pane :key="TABS.RESULTS" tab="Results" style="position: relative">
        <div
          style="
            position: absolute;
            left: 0;
            top: 0px;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
          "
        >
          <div class="heading">
            <div class="title">Cause-Effect</div>
          </div>
          <div class="sz-command-bar">
            <div class="left-command-bar">
              <a-button type="link" @click="getSurveySurrogates">
                <template #icon>
                  <Icon size="14">
                    <Settings />
                  </Icon>
                </template>
                <span>Refresh</span>
              </a-button>
            </div>
            <div class="right-command-bar">
              <a-space :space="8">
                <span>Scenarios: </span>
                <a-select
                  v-model:value="surveySurrogateSelectedId"
                  :options="surveySurrogateOptions"
                  size="small"
                  style="width: 200px; max-width: 400px"
                />
                <a-button type="link" @click="pickSurrogate">
                  <template #icon>
                    <Icon size="14">
                      <Settings />
                    </Icon>
                  </template>
                  <span>Load</span>
                </a-button>
                <a-tooltip placement="left" title="Add new scenario">
                  <a-button type="link" @click="addScenario">
                    <template #icon>
                      <IconPlaylistAdd :size="14" />
                    </template>
                  </a-button>
                </a-tooltip>
                <a-button type="link" @click="renameScenario">
                  <template #icon>
                    <IconEdit :size="14" color="#1890ff" />
                  </template>
                </a-button>
                <a-button type="link" @click="deleteScenario">
                  <template #icon>
                    <IconTrash :size="14" color="#1890ff" />
                  </template>
                </a-button>
                <!-- <a-button type="link" @click="exploreCauseEffect">
                  <template #icon>
                    <Icon size="14">
                      <Refresh />
                    </Icon>
                  </template>
                  <span>Reload data</span>
                </a-button> -->
              </a-space>
            </div>
          </div>
          <splitpanes class="default-theme" vertical>
            <pane min-size="10" size="50">
              <CauseEffectFromSurvey
                v-if="
                  selectedSurvey &&
                  selectedOutputVariable &&
                  allocations &&
                  selectedNetwork &&
                  nodeIds
                "
                :ref="
                  (el) => {
                    if (el) {
                      ceFromSurveyRef = el
                    }
                  }
                "
                :type="0"
                title="Survey"
                :variable="selectedOutputVariable"
                :allocations="allocations"
                :workspace-id="workspaceId"
                :survey="selectedSurvey"
                :survey-id="selectedSurvey?.id"
                :network="selectedNetwork"
                :user-map="userMap"
                :node-ids="nodeIds"
                :current-user="currentUser"
              />
            </pane>
            <pane min-size="10" size="50">
              <CauseEffectFromSurvey
                v-if="
                  selectedSurveySurrogate &&
                  selectedOutputVariable &&
                  allocations &&
                  selectedNetwork &&
                  nodeIds
                "
                :type="1"
                title="User"
                :variable="selectedOutputVariable"
                :allocations="allocations"
                :workspace-id="workspaceId"
                :survey="selectedSurveySurrogate"
                :survey-id="selectedSurveySurrogate?.id"
                :network="selectedNetwork"
                :user-map="userMap"
                :node-ids="nodeIds"
                :current-user="currentUser"
              />
            </pane>
          </splitpanes>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
  <Scenario
    :id="scenarioEditId"
    :name="scenarioName"
    :is-edit="isScenarioEdit"
    :is-visible="isScenarioVisible"
    @[SCENARIO_EVENTS.OK]="scenarioOk"
    @[SCENARIO_EVENTS.CANCEL]="isScenarioVisible = false"
  />
  <common-analysis
    v-model:palette-visible="paletteVisible"
    :is-loading="isCreatingJob || isUpdatingJob"
    :palette-name="paletteName"
    @[COMMON_EVENTS.COLOR_CHANGED]="selectPalette"
  />
</template>

<script lang="ts">
import 'splitpanes/dist/splitpanes.css'

import { IconEdit, IconPlaylistAdd, IconTrash } from '@tabler/icons-vue'
import { DeviceFloppy, Settings } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { indexBy, prop } from 'ramda'
import { Pane, Splitpanes } from 'splitpanes'
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue'
import { useRequest } from 'vue-request'
import { useRoute } from 'vue-router'

import CauseEffectFromSurvey from '@/components/analysis/cause-effect/CauseEffectFromSurvey.vue'
// import CauseEffectChart from '@/components/analysis/cause-effect/CauseEffectChart.vue'
// import CauseEffectTable, {
//   EVENTS as TABLE_EVENTS
// } from '@/components/analysis/cause-effect/CauseEffectTable.vue'
import CommonAnalysis, {
  EVENTS as COMMON_EVENTS
} from '@/components/analysis/common/CommonAnalysis.vue'
import CauseEffectNodeSelection, {
  EVENTS as VAR_EVENTS
} from '@/components/analysis/common/node-selection/NodeSelectionTable.vue'
import WorkspaceInfo from '@/components/analysis/common/workspace-info/WorkspaceInfo.vue'
import useAnalysis from '@/components/analysis/composable/analysis'
import useBase, { TABS } from '@/components/analysis/composable/base'
import useCauseEffect, {
  inputNodeMapper,
  outputNodeMapper
} from '@/components/analysis/composable/cause-effect'
import useJob from '@/components/analysis/composable/job'
import usePalette from '@/components/analysis/composable/palette'
import { NodeSelection } from '@/components/analysis/libs/common'
import { CATEGORICAL_PALETTES, ColorSchemeItem } from '@/components/common/color-scheme'
import useSurveyCommon from '@/components/composables/next/survey-common'
import SurveySelection, { EVENTS as SURVEY_EVENTS } from '@/components/survey/SurveySelection.vue'
import { EMIT_EVENTS } from '@/constants/emits'
import { ROUTE_NAME } from '@/constants/router'
import { getSurveys } from '@/services/api/survey'
dayjs.extend(relativeTime)
import { Network, Variable } from '@/libs/bayes'
import { logger } from '@/libs/logger'
import { useStore } from '@/store'
import { cssIcon } from '@/styles/common'
import { SurveySchema } from '@/types'
import { JobType } from '@/types/database/job'

import Scenario, { EVENTS as SCENARIO_EVENTS } from './Scenario.vue'

const CATEGORICAL_PALETTE_MAP = indexBy(prop('name'), CATEGORICAL_PALETTES)

export default defineComponent({
  components: {
    IconEdit,
    IconTrash,
    Settings,
    CauseEffectFromSurvey,
    CommonAnalysis,
    DeviceFloppy,
    // LayoutGridAdd,
    Icon,
    IconPlaylistAdd,
    Pane,
    Splitpanes,
    CauseEffectNodeSelection,
    // CauseEffectChart,
    // Refresh,
    // CauseEffectTable,
    // Palette,
    Scenario,
    SurveySelection,
    WorkspaceInfo
  },
  setup() {
    const ceFromSurveyRef: Ref<any> = ref(null)
    const store = useStore()
    const activeTab = ref(TABS.PARAMS)
    const route = useRoute()
    const routerParams = route.params
    let { workspaceId } = routerParams
    if (Array.isArray(workspaceId)) {
      workspaceId = workspaceId[0]
    }
    const table: Ref<any> = ref(null)
    const tableKey: Ref<number> = ref(0)
    const selectionConfig = {
      stateVisible: false,
      variationVisible: false,
      variationsVisible: false,
      constraintVisible: false,
      inputVisible: false,
      utilityVectorVisible: false,
      headers: {
        input: {
          title: 'Input',
          desc: 'Input Node'
        },
        output: {
          title: 'Output',
          desc: 'Output Node'
        }
      }
    }
    const {
      networks,
      loadWorkspace,
      loadNetworks,
      removeNetwork,
      workspace,
      workspaceCreated,
      workspaceModified
    } = useBase(workspaceId)

    const {
      paletteVisible,
      paletteName,
      paletteColors,
      selectPalette,
      showPalettePicker
    } = usePalette()

    const updateTable = () => {
      tableKey.value += 1
      table.value?.update(table.value)
    }

    const onSuccessSaveJob = () => {
      message.success('Analysis job is successfully saved')
    }

    const {
      networkMap,
      currentUser,
      nodeSelections,
      nodeSelectionMap,
      updateNodeSelections,
      inputNodeKeys,
      outputNodeKeys,
      updateInputOutputNodes,
      commonNodeKeys
    } = useAnalysis(workspaceId, JobType.CAUSEEFFECT, networks)

    const {
      tasks,
      loadJob,
      loadTasks,
      persistJob,
      currentJob,
      persistTasks,
      // addTask,
      // initTasks,
      // removeTask,
      isUpdatingJob,
      isCreatingJob
    } = useJob(
      currentUser,
      workspaceId,
      JobType.CAUSEEFFECT,
      networks,
      nodeSelections,
      nodeSelectionMap,
      inputNodeMapper,
      outputNodeMapper,
      onSuccessSaveJob
    )

    const {
      editSurveySurrogate2,
      runDeleteSurveySurrogate,
      addSurveySurrogate,
      onOutputNodeSelected,
      selectedSurvey,
      selectedSurveySurrogate,
      surveySurrogates,
      getSurveySurrogates,
      surveySurrogatesById
    } = useCauseEffect(
      workspaceId,
      networkMap,
      nodeSelections,
      nodeSelectionMap,
      inputNodeKeys,
      outputNodeKeys,
      tasks,
      updateTable,
      onSuccessSaveJob
    )

    const surveySurrogateSelectedId: Ref<string | undefined> = ref()
    const surveySurrogateOptions = computed(
      () =>
        surveySurrogates.value?.map((survey: SurveySchema) => ({
          label: survey.name,
          value: survey.id
        })) || []
    )

    const { allocations, userMap } = useSurveyCommon(store)

    const selectedOutputNode: Ref<any> = ref(null)

    const selectedOutputVariable: Ref<Variable | undefined> = computed(() => {
      if (selectedNetwork.value && selectedOutputNode.value) {
        return selectedNetwork.value.variableMapByKey[selectedOutputNode.value.key]
      }
      return undefined
    })

    const nodeIds = computed(() => {
      if (selectedOutputVariable.value) {
        return [selectedOutputVariable.value.key].concat(
          selectedNetwork.value.getParents(selectedOutputVariable.value).map((parent) => parent.key)
        )
      }
      return []
    })

    const selectedNetwork: Ref<Network> = ref(networks.value?.[0])

    const { run: runSurveys, data: surveyData } = useRequest(getSurveys, {
      manual: true
    })

    watch(surveyData, () => {
      selectedSurvey.value = surveyData.value?.content?.[0]
      if (selectedSurvey.value) {
        selectedSurveyIds.value = [selectedSurvey.value.id]
      }
    })

    // get this from job
    const selectedSurveyIds: Ref<string[]> = ref([])

    const surveySelectionChange = (selectedSurveyIds_: string[], selectedSurvey_: SurveySchema) => {
      selectedSurveyIds.value = selectedSurveyIds_
      selectedSurvey.value = selectedSurvey_
    }

    const pickSurrogate = () => {
      if (surveySurrogateSelectedId.value) {
        const selectedSurveySurrogate_ = surveySurrogatesById.value[surveySurrogateSelectedId.value]
        if (selectedSurveySurrogate_) {
          selectedSurveySurrogate.value = selectedSurveySurrogate_
        }
      }
    }

    const handleLoadNetwork = () => {
      loadNetworks()
    }

    const calculate = () => {
      updateTable()
    }

    const preCalculate = () => {
      updateTable()
    }

    const toggleInput = () => {
      updateInputOutputNodes()
      // updateCauseEffectRows()
    }
    const toggleOutput = (record: NodeSelection) => {
      // updateInputOutputNodes()
      onOutputNodeSelected(record)
      if (record.isOutput) {
        selectedOutputNode.value = record
      } else {
        selectedOutputNode.value = null
      }
      // updateCauseEffectRows()
    }

    watch(networks, async () => {
      logger.info('watch - networks')
      updateNodeSelections()
      await loadJob()
      await loadTasks()
      updateInputOutputNodes()
      // updateCauseEffectRows()
      getSurveySurrogates()
    })

    watch(nodeSelections, () => {
      // updateCauseEffectRows()
    })

    onMounted(() => {
      if (workspaceId) {
        runSurveys(workspaceId as string)
      }
      loadWorkspace()
      loadNetworks()
    })

    const detailVisible: Ref<boolean> = ref(false)

    watch(currentJob, () => {
      if (surveyData.value?.content?.length) {
        const persistedSurveyId =
          currentJob.value?.params?.config?.causeEffectConfig?.surveyId || null
        surveyData.value?.content.forEach((survey: any) => {
          if (survey.id === persistedSurveyId) {
            selectedSurvey.value = survey
            selectedSurveyIds.value = [survey.id]
          }
        })
      }
    })

    watch(outputNodeKeys, () => {
      if (nodeSelectionMap.value && outputNodeKeys.value?.length) {
        selectedOutputNode.value = nodeSelectionMap.value[outputNodeKeys.value[0]]
      }
    })

    const saveJob = async () => {
      await persistJob({
        causeEffectConfig: {
          surveyId: selectedSurvey.value?.id || null
        }
      })
      await persistTasks()
    }

    const selectTaskPalette = () => {
      paletteColors.value = CATEGORICAL_PALETTE_MAP[paletteName.value]?.items?.map(
        ({ color }: ColorSchemeItem) => color
      )
      paletteVisible.value = false
    }

    const exploreCauseEffect = () => {
      ceFromSurveyRef.value?.refresh()
    }

    const isScenarioEdit = ref(false)
    const isScenarioVisible = ref(false)
    const scenarioName = ref('')
    const scenarioEditId = ref('')

    watch(selectedSurveySurrogate, () => {
      if (selectedSurveySurrogate.value) {
        surveySurrogateSelectedId.value = selectedSurveySurrogate.value.id
      }
    })

    const scenarioOk = (scenarioName: string, scenarioId: string) => {
      if (isScenarioEdit.value) {
        editSurveySurrogate2(scenarioId, scenarioName)
      } else {
        addSurveySurrogate(scenarioName)
      }
      getSurveySurrogates()
      isScenarioVisible.value = false
    }

    const addScenario = () => {
      scenarioName.value = selectedSurvey.value?.name + '-' + (surveySurrogates.value.length + 1)
      isScenarioVisible.value = true
      isScenarioEdit.value = false
    }

    const deleteScenario = async () => {
      if (!surveySurrogateSelectedId.value) {
        return
      }
      await runDeleteSurveySurrogate(surveySurrogateSelectedId.value)
      // @todo make thi sync
      setTimeout(() => {
        getSurveySurrogates()
        selectedSurveySurrogate.value = undefined
        surveySurrogateSelectedId.value = undefined
      }, 500)
    }

    const renameScenario = () => {
      if (!surveySurrogateSelectedId.value) {
        return
      }
      const selectedSurveyForEdit = surveySurrogatesById.value[surveySurrogateSelectedId.value]
      if (!selectedSurveyForEdit) {
        return
      }
      scenarioEditId.value = selectedSurveyForEdit.id
      scenarioName.value = selectedSurveyForEdit.name
      isScenarioVisible.value = true
      isScenarioEdit.value = true
    }

    watch(networks, async () => {
      selectedNetwork.value = networks.value?.[0]
    })

    // Do this explicitly for now
    // watch(selectedSurvey, () => {
    //   // initSurveySurrogate()
    // })

    const initExplore = () => {
      // initSurveySurrogate()
    }

    const onShowDetail = () => {
      detailVisible.value = !detailVisible.value
    }

    return {
      scenarioEditId,
      scenarioName,
      deleteScenario,
      scenarioOk,
      renameScenario,
      addScenario,
      getSurveySurrogates,
      addSurveySurrogate,
      pickSurrogate,
      surveySurrogateSelectedId,
      surveySurrogateOptions,
      surveySurrogates,
      selectedSurveySurrogate,
      initExplore,
      selectedOutputVariable: selectedOutputVariable,
      allocations,
      userMap,
      detailVisible,
      nodeIds,
      selectedOutputNode,
      selectedNetwork,
      ceFromSurveyRef,
      exploreCauseEffect,
      activeTab,
      calculate,
      selectedSurveyIds,
      surveySelectionChange,
      selectedSurvey,
      SURVEY_EVENTS,
      CATEGORICAL_PALETTE_MAP,
      CATEGORICAL_PALETTES,
      COMMON_EVENTS,
      commonNodeKeys,
      cssIcon,
      EMIT_EVENTS,
      handleLoadNetwork,
      currentUser,
      inputNodeKeys,
      isCreatingJob,
      isUpdatingJob,
      loadNetworks,
      networks,
      nodeSelections,
      outputNodeKeys,
      paletteColors,
      paletteName,
      paletteVisible,
      preCalculate,
      removeNetwork,
      ROUTE_NAME,
      saveJob,
      selectionConfig,
      selectPalette,
      selectTaskPalette,
      showPalettePicker,
      table,
      tableKey,
      TABS,
      tasks,
      toggleInput,
      toggleOutput,
      VAR_EVENTS,
      workspace,
      workspaceCreated,
      workspaceId,
      onShowDetail,
      workspaceModified,
      SCENARIO_EVENTS,
      isScenarioEdit,
      isScenarioVisible
    }
  }
})
</script>

<style lang="stylus">
@import "../../../styles/base.styl"
@import "../../../styles/commons.styl"
@import "../styles/common.styl"
.sz-page-spinner
  position: fixed
  left: 0
  top: 0
  right: 0
  bottom: 0
  display: flex
  padding: 0
  margin: 0
  background-color: rgba(255, 255, 255, 0.5)
  > div
    @extend .centered
    margin auto

.sz-cause-effect
  .sz-command-bar
    > *
      display: flex
      flex-direction: row
    .left-command-bar
      width: 50%
    .right-command-bar
      width: 50%
      justify-content: flex-end;
</style>
