import { keyBy } from 'lodash-es'
import { computed } from 'vue'

import { ModuleNames } from '@/constants/vuex'
import { AllocationStateEnum, AuthStateEnum, UserStateEnum } from '@/store/enums/states'
import { Store } from '@/store/types'

const { AUTH, ALLOCATION } = ModuleNames

export default function useSurveyCommon(store: Store): any {
  const currentUser = computed(() => store.state[AUTH][AuthStateEnum.USER])
  const userMap = computed(() => keyBy(store.state.user[UserStateEnum.USER_LIST]?.content, 'id'))
  const allocations = computed(
    () => store.state[ALLOCATION][AllocationStateEnum.ALLOCATION_LIST]?.content
  )

  return {
    allocations,
    userMap,
    currentUser
  }
}
