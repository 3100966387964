import { render } from "./CauseEffectWorkspace.vue?vue&type=template&id=703b8f72"
import script from "./CauseEffectWorkspace.vue?vue&type=script&lang=ts"
export * from "./CauseEffectWorkspace.vue?vue&type=script&lang=ts"

import "./CauseEffectWorkspace.vue?vue&type=style&index=0&id=703b8f72&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "703b8f72"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('703b8f72', script)) {
    api.reload('703b8f72', script)
  }
  
  module.hot.accept("./CauseEffectWorkspace.vue?vue&type=template&id=703b8f72", () => {
    api.rerender('703b8f72', render)
  })

}

script.__file = "src/components/analysis/cause-effect/CauseEffectWorkspace.vue"

export default script