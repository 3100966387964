/* eslint-disable @typescript-eslint/no-unused-vars */
import { keyBy } from 'lodash-es'
import { computed, Ref, ref, watch } from 'vue'
import { useRequest } from 'vue-request'

import {
  AnalysisTask,
  InputNode,
  NodeSelection,
  OutputNode
} from '@/components/analysis/libs/common'
import { Network } from '@/libs/bayes'
import { cloneSurvey, deleteSurvey, getSurveys, updateSurvey } from '@/services/api/survey'
import { SurveySchema } from '@/types'
import { JobInputNode, JobOutputNode } from '@/types/database/job'

export const OMITTED = 'OMIT'
export const ANALYSIS_API: 'v1' | 'v2' | 'v3' = 'v2' // v0, v1 and v2

export const inputNodeMapper = ({ key, state, values }: InputNode): JobInputNode => {
  return {
    key,
    state,
    values
  }
}

export const outputNodeMapper = ({ key, utilityVector }: OutputNode): JobOutputNode => {
  return {
    key,
    utilityVector
  }
}

export default function useCauseEffect(
  workspaceId: string,
  networkMap: Ref<Record<string, Network>>,
  nodeSelections: Ref<NodeSelection[]>,
  nodeSelectionMap: Ref<Record<string, NodeSelection>>,
  inputNodeKeys: Ref<string[]>,
  outputNodeKeys: Ref<string[]>,
  tasks: Ref<AnalysisTask[]>,
  refreshCallback: () => void,
  successCallback: () => void = () => undefined
): any {
  const selectedSurvey: Ref<SurveySchema | undefined> = ref()
  const selectedSurveySurrogate: Ref<SurveySchema | undefined> = ref()
  const surveySurrogates: Ref<SurveySchema[]> = ref([])
  const surveySurrogatesById = computed(() => keyBy(surveySurrogates.value || [], 'id'))

  const { run: runCloneSurvey, data: newSurveyClonedData } = useRequest(cloneSurvey, {
    manual: true
  })

  const { run: runGetSurveySurrogates, data: surveySurrogatesData } = useRequest(getSurveys, {
    manual: true
  })

  const { run: runUpdateSurvey } = useRequest(updateSurvey, {
    manual: true
  })

  const { run: runDeleteSurveySurrogate } = useRequest(deleteSurvey, {
    manual: true
  })

  const addSurveySurrogate = (scenarioName: string): boolean => {
    if (!selectedSurvey.value) {
      return false
    }
    runCloneSurvey(
      selectedSurvey.value.id,
      scenarioName
        ? scenarioName
        : selectedSurvey.value?.name + '-' + (surveySurrogates.value.length + 1)
    )
    return true
  }

  const editSurveySurrogate = (scenarioName: string): boolean => {
    if (!selectedSurveySurrogate.value) {
      return false
    }
    runUpdateSurvey({
      id: selectedSurveySurrogate.value.id,
      survey: {
        name: scenarioName
      }
    })
    return true
  }

  const editSurveySurrogate2 = (id: string, scenarioName: string): boolean => {
    if (!id) {
      return false
    }
    runUpdateSurvey({
      id,
      survey: {
        name: scenarioName,
        surrogate: true
      }
    })
    return true
  }

  const getSurveySurrogates = (): boolean => {
    runGetSurveySurrogates(workspaceId, { surrogate: true })
    return true
  }

  watch(surveySurrogatesData, () => {
    surveySurrogates.value = surveySurrogatesData.value?.content || []
  })

  watch(newSurveyClonedData, async () => {
    await getSurveySurrogates()
    selectedSurveySurrogate.value = newSurveyClonedData.value
  })

  const onOutputNodeSelected = (selectedNode: NodeSelection) => {
    nodeSelections.value?.forEach((node: NodeSelection) => {
      if (selectedNode.key === node.key) {
        node.isOutput = true
      } else {
        node.isOutput = false
      }
    })
  }

  return {
    editSurveySurrogate2,
    runDeleteSurveySurrogate,
    editSurveySurrogate,
    surveySurrogatesById,
    addSurveySurrogate,
    getSurveySurrogates,
    selectedSurvey,
    selectedSurveySurrogate,
    onOutputNodeSelected,
    surveySurrogates
  }
}
