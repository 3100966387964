<template>
  <a-modal :title="title" width="600px" :visible="isVisible" @ok="ok" @cancel="cancel">
    <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
      <a-form-item label="Name">
        <a-input v-model:value="scenarioName" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'

export const EVENTS = {
  OK: 'OK',
  CANCEL: 'CANCEL'
}

export default defineComponent({
  props: {
    isVisible: { type: Boolean, default: false },
    isEdit: { type: Boolean, default: false },
    id: { type: String, default: undefined },
    name: { type: String, default: undefined }
  },
  emits: Object.values(EVENTS),
  setup(props, { emit }) {
    const scenarioName = ref(props.name)
    const title = computed(() => `${props.isEdit ? 'Edit' : 'Add new'} scenario`)

    watch(
      () => props.name,
      (): void => {
        scenarioName.value = props.name
      }
    )

    const ok = () => {
      emit(EVENTS.OK, scenarioName.value, props.id)
    }

    const cancel = () => {
      emit(EVENTS.CANCEL)
    }

    return {
      title,
      scenarioName,
      ok,
      cancel
    }
  }
})
</script>
