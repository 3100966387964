<template>
  <div class="sza-ce-probs">
    <a-table size="small" :data-source="data" :columns="columns" :pagination="false">
      <template #name="{ record }">
        <div v-if="record.type === ROW_TYPE.LABEL" style="padding: 3px; background-color: #f0f0f0">
          {{ record.name }}
        </div>
        <div v-else style="padding: 3px; position: relative">
          {{ record.name }}
          <a-button
            type="link"
            style="position: absolute; top: -3px; right: -5px"
            @click="selectVariable(record.variable)"
          >
            <template #icon>
              <Icon :class="cssIcon">
                <IconTable />
              </Icon>
            </template>
          </a-button>
        </div>
      </template>
      <template #value="{ text, record }">
        <div
          v-if="text && record.type === ROW_TYPE.LABEL"
          style="padding: 3px; background-color: #f0f0f0"
        >
          &nbsp;
        </div>
        <div v-if="record.type === ROW_TYPE.OUTPUT" style="position: relative">
          <div
            v-for="(index_, i) in [1]"
            :key="i"
            style="display: flex; flex-direction: row"
            class="sz-ce-p"
          >
            <div
              v-for="(state_, j) in record.states"
              :key="j"
              :style="cellBgStyle(text?.[index_]?.[j])"
            >
              <a-tooltip placement="top">
                <template #title>
                  <span>{{ state_ }}</span>
                </template>
                {{ (Number(text?.[index_]?.[j] || 0) * 100).toFixed(4) }}
              </a-tooltip>
            </div>
          </div>
        </div>
        <div
          v-if="record.type === ROW_TYPE.INPUT"
          style="display: flex; flex-direction: row"
          class="sz-ce-p"
        >
          <div
            v-for="(state_, j) in record.states"
            :key="j"
            :style="cellBgStyle(text?.[text?.length - 1]?.[j])"
          >
            <a-tooltip placement="top">
              <template #title>
                <span>{{ state_ }}</span>
              </template>
              {{ (Number(text?.[text?.length - 1]?.[j] || 0) * 100).toFixed(4) }}
            </a-tooltip>
          </div>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script lang="ts">
import { Table as IconTable } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import { computed, defineComponent, PropType } from 'vue'

import { InputType, ROW_TYPE } from '@/components/analysis/libs/common'
import { Network, Variable } from '@/libs/bayes'
import { genCellBgStyleRange } from '@/libs/theme'
import { cssIcon } from '@/styles/common'

export const EVENTS = {
  ON_SELECT_VARIABLE: 'ON_SELECT_VARIABLE'
}

export default defineComponent({
  components: {
    Icon,
    IconTable
  },
  props: {
    title: { type: String, required: true },
    selectedOutputNode: { type: Object, required: true },
    selectedNetwork: { type: Object as PropType<Network | null>, required: true },
    probMap: { type: Object, default: null }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const columns = computed(() => {
      const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          align: 'left',
          slots: {
            customRender: 'name'
          }
        },
        {
          title: props.title,
          dataIndex: 'value',
          key: 'value',
          align: 'center',
          slots: {
            customRender: 'value'
          }
        }
      ]
      return columns
    })

    const getProb = (key: string) => {
      if (props.probMap && props.probMap[key]) {
        return props.probMap[key]
      }
      return [[]]
    }

    const data = computed(() => {
      if (!props.selectedOutputNode || !props.selectedNetwork) {
        return []
      }

      const net: Network = props.selectedNetwork
      const key = props.selectedOutputNode.key
      const outputVar = net.variableMapByKey[key]
      const name = outputVar.name
      const parents = net.parents[outputVar.id]
      let rows = [
        {
          type: ROW_TYPE.LABEL,
          name: 'Output Node',
          value: '',
          key: 'out-label',
          states: [''],
          variable: outputVar
        }
      ]
      rows.push({
        type: ROW_TYPE.OUTPUT,
        name: name,
        value: getProb(key),
        key: name,
        variable: outputVar,
        states: outputVar.getAllStates().map(({ state }) => state.name)
      })
      rows.push({
        type: ROW_TYPE.LABEL,
        name: 'Parents',
        value: '',
        key: 'parents-label',
        states: [''],
        variable: outputVar
      })
      parents?.forEach((parent: Variable) => {
        const key = parent.key || ''
        rows.push({
          type: ROW_TYPE.INPUT,
          name: parent.name || '',
          key,
          variable: parent,
          value: getProb(key),
          states: parent.getAllStates().map(({ state }) => state.name)
        })
      })
      return rows
    })

    const cellBgStyle = (val: number) => {
      return genCellBgStyleRange(0, 1, val)
    }

    const update = (comp: any) => {
      comp.$forceUpdate()
    }

    const selectVariable = (variable: Variable) => {
      emit(EVENTS.ON_SELECT_VARIABLE, variable)
    }

    return {
      selectVariable,
      update,
      data,
      columns,
      cellBgStyle,
      cssIcon,
      ROW_TYPE,
      EVENTS,
      InputType
    }
  }
})
</script>

<style lang="stylus">
@import "../../../styles/ice-table.styl"
@import "../../../styles/commons.styl"

.sza-ce-probs
  overflow auto
  .ant-table-thead
    th
      padding 4px !important
  .ant-table-row
    > td
      padding 0 !important
      height: 24px
    > td:first-child
      padding-left 10px !important
  .sza-whatif-actions
      padding 3px 0 1px 0 !important

  .sz-ce-p
    height: 100%
    display: flex
    flex-direction: row
    align-items: stretch
    &>div
      flex: 1 1 auto
      text-align: center
      line-height: 24px
</style>
