
import 'splitpanes/dist/splitpanes.css'

import { IconEdit, IconPlaylistAdd, IconTrash } from '@tabler/icons-vue'
import { DeviceFloppy, Settings } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { indexBy, prop } from 'ramda'
import { Pane, Splitpanes } from 'splitpanes'
import { computed, defineComponent, onMounted, Ref, ref, watch } from 'vue'
import { useRequest } from 'vue-request'
import { useRoute } from 'vue-router'

import CauseEffectFromSurvey from '@/components/analysis/cause-effect/CauseEffectFromSurvey.vue'
// import CauseEffectChart from '@/components/analysis/cause-effect/CauseEffectChart.vue'
// import CauseEffectTable, {
//   EVENTS as TABLE_EVENTS
// } from '@/components/analysis/cause-effect/CauseEffectTable.vue'
import CommonAnalysis, {
  EVENTS as COMMON_EVENTS
} from '@/components/analysis/common/CommonAnalysis.vue'
import CauseEffectNodeSelection, {
  EVENTS as VAR_EVENTS
} from '@/components/analysis/common/node-selection/NodeSelectionTable.vue'
import WorkspaceInfo from '@/components/analysis/common/workspace-info/WorkspaceInfo.vue'
import useAnalysis from '@/components/analysis/composable/analysis'
import useBase, { TABS } from '@/components/analysis/composable/base'
import useCauseEffect, {
  inputNodeMapper,
  outputNodeMapper
} from '@/components/analysis/composable/cause-effect'
import useJob from '@/components/analysis/composable/job'
import usePalette from '@/components/analysis/composable/palette'
import { NodeSelection } from '@/components/analysis/libs/common'
import { CATEGORICAL_PALETTES, ColorSchemeItem } from '@/components/common/color-scheme'
import useSurveyCommon from '@/components/composables/next/survey-common'
import SurveySelection, { EVENTS as SURVEY_EVENTS } from '@/components/survey/SurveySelection.vue'
import { EMIT_EVENTS } from '@/constants/emits'
import { ROUTE_NAME } from '@/constants/router'
import { getSurveys } from '@/services/api/survey'
dayjs.extend(relativeTime)
import { Network, Variable } from '@/libs/bayes'
import { logger } from '@/libs/logger'
import { useStore } from '@/store'
import { cssIcon } from '@/styles/common'
import { SurveySchema } from '@/types'
import { JobType } from '@/types/database/job'

import Scenario, { EVENTS as SCENARIO_EVENTS } from './Scenario.vue'

const CATEGORICAL_PALETTE_MAP = indexBy(prop('name'), CATEGORICAL_PALETTES)

export default defineComponent({
  components: {
    IconEdit,
    IconTrash,
    Settings,
    CauseEffectFromSurvey,
    CommonAnalysis,
    DeviceFloppy,
    // LayoutGridAdd,
    Icon,
    IconPlaylistAdd,
    Pane,
    Splitpanes,
    CauseEffectNodeSelection,
    // CauseEffectChart,
    // Refresh,
    // CauseEffectTable,
    // Palette,
    Scenario,
    SurveySelection,
    WorkspaceInfo
  },
  setup() {
    const ceFromSurveyRef: Ref<any> = ref(null)
    const store = useStore()
    const activeTab = ref(TABS.PARAMS)
    const route = useRoute()
    const routerParams = route.params
    let { workspaceId } = routerParams
    if (Array.isArray(workspaceId)) {
      workspaceId = workspaceId[0]
    }
    const table: Ref<any> = ref(null)
    const tableKey: Ref<number> = ref(0)
    const selectionConfig = {
      stateVisible: false,
      variationVisible: false,
      variationsVisible: false,
      constraintVisible: false,
      inputVisible: false,
      utilityVectorVisible: false,
      headers: {
        input: {
          title: 'Input',
          desc: 'Input Node'
        },
        output: {
          title: 'Output',
          desc: 'Output Node'
        }
      }
    }
    const {
      networks,
      loadWorkspace,
      loadNetworks,
      removeNetwork,
      workspace,
      workspaceCreated,
      workspaceModified
    } = useBase(workspaceId)

    const {
      paletteVisible,
      paletteName,
      paletteColors,
      selectPalette,
      showPalettePicker
    } = usePalette()

    const updateTable = () => {
      tableKey.value += 1
      table.value?.update(table.value)
    }

    const onSuccessSaveJob = () => {
      message.success('Analysis job is successfully saved')
    }

    const {
      networkMap,
      currentUser,
      nodeSelections,
      nodeSelectionMap,
      updateNodeSelections,
      inputNodeKeys,
      outputNodeKeys,
      updateInputOutputNodes,
      commonNodeKeys
    } = useAnalysis(workspaceId, JobType.CAUSEEFFECT, networks)

    const {
      tasks,
      loadJob,
      loadTasks,
      persistJob,
      currentJob,
      persistTasks,
      // addTask,
      // initTasks,
      // removeTask,
      isUpdatingJob,
      isCreatingJob
    } = useJob(
      currentUser,
      workspaceId,
      JobType.CAUSEEFFECT,
      networks,
      nodeSelections,
      nodeSelectionMap,
      inputNodeMapper,
      outputNodeMapper,
      onSuccessSaveJob
    )

    const {
      editSurveySurrogate2,
      runDeleteSurveySurrogate,
      addSurveySurrogate,
      onOutputNodeSelected,
      selectedSurvey,
      selectedSurveySurrogate,
      surveySurrogates,
      getSurveySurrogates,
      surveySurrogatesById
    } = useCauseEffect(
      workspaceId,
      networkMap,
      nodeSelections,
      nodeSelectionMap,
      inputNodeKeys,
      outputNodeKeys,
      tasks,
      updateTable,
      onSuccessSaveJob
    )

    const surveySurrogateSelectedId: Ref<string | undefined> = ref()
    const surveySurrogateOptions = computed(
      () =>
        surveySurrogates.value?.map((survey: SurveySchema) => ({
          label: survey.name,
          value: survey.id
        })) || []
    )

    const { allocations, userMap } = useSurveyCommon(store)

    const selectedOutputNode: Ref<any> = ref(null)

    const selectedOutputVariable: Ref<Variable | undefined> = computed(() => {
      if (selectedNetwork.value && selectedOutputNode.value) {
        return selectedNetwork.value.variableMapByKey[selectedOutputNode.value.key]
      }
      return undefined
    })

    const nodeIds = computed(() => {
      if (selectedOutputVariable.value) {
        return [selectedOutputVariable.value.key].concat(
          selectedNetwork.value.getParents(selectedOutputVariable.value).map((parent) => parent.key)
        )
      }
      return []
    })

    const selectedNetwork: Ref<Network> = ref(networks.value?.[0])

    const { run: runSurveys, data: surveyData } = useRequest(getSurveys, {
      manual: true
    })

    watch(surveyData, () => {
      selectedSurvey.value = surveyData.value?.content?.[0]
      if (selectedSurvey.value) {
        selectedSurveyIds.value = [selectedSurvey.value.id]
      }
    })

    // get this from job
    const selectedSurveyIds: Ref<string[]> = ref([])

    const surveySelectionChange = (selectedSurveyIds_: string[], selectedSurvey_: SurveySchema) => {
      selectedSurveyIds.value = selectedSurveyIds_
      selectedSurvey.value = selectedSurvey_
    }

    const pickSurrogate = () => {
      if (surveySurrogateSelectedId.value) {
        const selectedSurveySurrogate_ = surveySurrogatesById.value[surveySurrogateSelectedId.value]
        if (selectedSurveySurrogate_) {
          selectedSurveySurrogate.value = selectedSurveySurrogate_
        }
      }
    }

    const handleLoadNetwork = () => {
      loadNetworks()
    }

    const calculate = () => {
      updateTable()
    }

    const preCalculate = () => {
      updateTable()
    }

    const toggleInput = () => {
      updateInputOutputNodes()
      // updateCauseEffectRows()
    }
    const toggleOutput = (record: NodeSelection) => {
      // updateInputOutputNodes()
      onOutputNodeSelected(record)
      if (record.isOutput) {
        selectedOutputNode.value = record
      } else {
        selectedOutputNode.value = null
      }
      // updateCauseEffectRows()
    }

    watch(networks, async () => {
      logger.info('watch - networks')
      updateNodeSelections()
      await loadJob()
      await loadTasks()
      updateInputOutputNodes()
      // updateCauseEffectRows()
      getSurveySurrogates()
    })

    watch(nodeSelections, () => {
      // updateCauseEffectRows()
    })

    onMounted(() => {
      if (workspaceId) {
        runSurveys(workspaceId as string)
      }
      loadWorkspace()
      loadNetworks()
    })

    const detailVisible: Ref<boolean> = ref(false)

    watch(currentJob, () => {
      if (surveyData.value?.content?.length) {
        const persistedSurveyId =
          currentJob.value?.params?.config?.causeEffectConfig?.surveyId || null
        surveyData.value?.content.forEach((survey: any) => {
          if (survey.id === persistedSurveyId) {
            selectedSurvey.value = survey
            selectedSurveyIds.value = [survey.id]
          }
        })
      }
    })

    watch(outputNodeKeys, () => {
      if (nodeSelectionMap.value && outputNodeKeys.value?.length) {
        selectedOutputNode.value = nodeSelectionMap.value[outputNodeKeys.value[0]]
      }
    })

    const saveJob = async () => {
      await persistJob({
        causeEffectConfig: {
          surveyId: selectedSurvey.value?.id || null
        }
      })
      await persistTasks()
    }

    const selectTaskPalette = () => {
      paletteColors.value = CATEGORICAL_PALETTE_MAP[paletteName.value]?.items?.map(
        ({ color }: ColorSchemeItem) => color
      )
      paletteVisible.value = false
    }

    const exploreCauseEffect = () => {
      ceFromSurveyRef.value?.refresh()
    }

    const isScenarioEdit = ref(false)
    const isScenarioVisible = ref(false)
    const scenarioName = ref('')
    const scenarioEditId = ref('')

    watch(selectedSurveySurrogate, () => {
      if (selectedSurveySurrogate.value) {
        surveySurrogateSelectedId.value = selectedSurveySurrogate.value.id
      }
    })

    const scenarioOk = (scenarioName: string, scenarioId: string) => {
      if (isScenarioEdit.value) {
        editSurveySurrogate2(scenarioId, scenarioName)
      } else {
        addSurveySurrogate(scenarioName)
      }
      getSurveySurrogates()
      isScenarioVisible.value = false
    }

    const addScenario = () => {
      scenarioName.value = selectedSurvey.value?.name + '-' + (surveySurrogates.value.length + 1)
      isScenarioVisible.value = true
      isScenarioEdit.value = false
    }

    const deleteScenario = async () => {
      if (!surveySurrogateSelectedId.value) {
        return
      }
      await runDeleteSurveySurrogate(surveySurrogateSelectedId.value)
      // @todo make thi sync
      setTimeout(() => {
        getSurveySurrogates()
        selectedSurveySurrogate.value = undefined
        surveySurrogateSelectedId.value = undefined
      }, 500)
    }

    const renameScenario = () => {
      if (!surveySurrogateSelectedId.value) {
        return
      }
      const selectedSurveyForEdit = surveySurrogatesById.value[surveySurrogateSelectedId.value]
      if (!selectedSurveyForEdit) {
        return
      }
      scenarioEditId.value = selectedSurveyForEdit.id
      scenarioName.value = selectedSurveyForEdit.name
      isScenarioVisible.value = true
      isScenarioEdit.value = true
    }

    watch(networks, async () => {
      selectedNetwork.value = networks.value?.[0]
    })

    // Do this explicitly for now
    // watch(selectedSurvey, () => {
    //   // initSurveySurrogate()
    // })

    const initExplore = () => {
      // initSurveySurrogate()
    }

    const onShowDetail = () => {
      detailVisible.value = !detailVisible.value
    }

    return {
      scenarioEditId,
      scenarioName,
      deleteScenario,
      scenarioOk,
      renameScenario,
      addScenario,
      getSurveySurrogates,
      addSurveySurrogate,
      pickSurrogate,
      surveySurrogateSelectedId,
      surveySurrogateOptions,
      surveySurrogates,
      selectedSurveySurrogate,
      initExplore,
      selectedOutputVariable: selectedOutputVariable,
      allocations,
      userMap,
      detailVisible,
      nodeIds,
      selectedOutputNode,
      selectedNetwork,
      ceFromSurveyRef,
      exploreCauseEffect,
      activeTab,
      calculate,
      selectedSurveyIds,
      surveySelectionChange,
      selectedSurvey,
      SURVEY_EVENTS,
      CATEGORICAL_PALETTE_MAP,
      CATEGORICAL_PALETTES,
      COMMON_EVENTS,
      commonNodeKeys,
      cssIcon,
      EMIT_EVENTS,
      handleLoadNetwork,
      currentUser,
      inputNodeKeys,
      isCreatingJob,
      isUpdatingJob,
      loadNetworks,
      networks,
      nodeSelections,
      outputNodeKeys,
      paletteColors,
      paletteName,
      paletteVisible,
      preCalculate,
      removeNetwork,
      ROUTE_NAME,
      saveJob,
      selectionConfig,
      selectPalette,
      selectTaskPalette,
      showPalettePicker,
      table,
      tableKey,
      TABS,
      tasks,
      toggleInput,
      toggleOutput,
      VAR_EVENTS,
      workspace,
      workspaceCreated,
      workspaceId,
      onShowDetail,
      workspaceModified,
      SCENARIO_EVENTS,
      isScenarioEdit,
      isScenarioVisible
    }
  }
})
