import { render } from "./Scenario.vue?vue&type=template&id=8fff46b2"
import script from "./Scenario.vue?vue&type=script&lang=ts"
export * from "./Scenario.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "8fff46b2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('8fff46b2', script)) {
    api.reload('8fff46b2', script)
  }
  
  module.hot.accept("./Scenario.vue?vue&type=template&id=8fff46b2", () => {
    api.rerender('8fff46b2', render)
  })

}

script.__file = "src/components/analysis/cause-effect/Scenario.vue"

export default script