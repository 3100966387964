<template>
  <div v-if="network && network.variables" class="sz-survey-ce">
    <div>
      <div class="sz-command-bar">
        <a-space align="center" :size="0">
          <!--<a-button type="link" @click="publishMock">Export Sample XDSL</a-button>-->
          <!-- <a-button type="link" @click="refresh">Reload data</a-button> -->
          <a-button type="link" @click="calcProbs">Update Post.</a-button>
          <a-button type="link" @click="publishAgg">Export XDSL</a-button>
          <a-button type="link" @click="exportSurveyData">Export XLS</a-button>
          <a-checkbox v-model:checked="isAnonymous" />
          Anonymous
        </a-space>
      </div>
      <div class="sz-ce-prob-table">
        <CauseEffectProbs
          title="Survey"
          :selected-output-node="variable"
          :selected-network="network"
          :prob-map="probMap"
          @[PROB_EVENTS.ON_SELECT_VARIABLE]="selectVariable"
        />
      </div>
      <div class="sz-ce-cpt-table">
        <SurveyAnalyticsSimple
          v-if="
            selectedVariable &&
            analyticsMap &&
            persistedResponses &&
            persistedResponses.length &&
            userMap &&
            survey &&
            cpt
          "
          :variable="selectedVariable"
          :analytics-map="analyticsMap"
          :user-map="userMap"
          :current-user="currentUser"
          :responses="persistedResponses"
          :workspace-id="workspaceId"
          :survey="survey"
          :survey-id="survey?.id"
          :network="network"
          :cpt="cpt"
          :editable="type === 1"
          :is-anonymous="isAnonymous"
          :has-discrete-scale="hasDiscreteScale"
          @[SIMPLE_EVENTS.ON_RESPONSES_CHANGE]="onResponsesSave"
        ></SurveyAnalyticsSimple>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import 'splitpanes/dist/splitpanes.css'

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { message } from 'ant-design-vue'
import { isNil } from 'ramda'
import { defineComponent, onMounted, PropType, ref, toRefs, watch } from 'vue'

import CauseEffectProbs, {
  EVENTS as PROB_EVENTS
} from '@/components/analysis/cause-effect/CauseEffectProbs.vue'
import useAnalytics, { exportAnalytics } from '@/components/composables/analytics'
import useSurvey from '@/components/composables/next/survey'
import useSurveyPrep from '@/components/composables/next/survey-prep'
import { TABLE_EVENTS } from '@/components/method-common/Table'
import SurveyAnalyticsSimple, {
  EVENTS as SIMPLE_EVENTS
} from '@/components/survey/SurveyAnalyticsSimple.vue'
import { DB_ENUM_VALUES } from '@/constants/database'
import { EVENTS } from '@/constants/emits'
import { MESSAGE } from '@/constants/message'
import { ROUTE_NAME } from '@/constants/router'
import { Network, Variable } from '@/libs/bayes'
import { persistSummaries, trace } from '@/services/composition/survey'
import { useStore } from '@/store'
import { Allocation, ResponseSchema, SurveyExportFormatEnum, SurveySchema, User } from '@/types'

export default defineComponent({
  components: {
    CauseEffectProbs,
    SurveyAnalyticsSimple
  },
  props: {
    title: { type: String, default: '' },
    type: { type: Number, default: 0 },
    workspaceId: { type: String, required: true },
    surveyId: { type: String, required: true },
    variable: { type: Object as PropType<Variable>, required: true },
    survey: { type: Object as PropType<SurveySchema>, required: true },
    currentUser: { type: Object as PropType<User>, required: true },
    userMap: { type: Object, required: true },
    network: { type: Object as PropType<Network>, required: true },
    allocations: { type: Object as PropType<Allocation[]>, required: true },
    nodeIds: { type: Array as PropType<string[]>, required: true }
  },
  setup(props) {
    const store = useStore()
    const isAnonymous = ref(true)
    const probMap = ref()
    const selectedVariable = ref(props.variable)
    const { surveyId, network, currentUser, userMap, allocations } = toRefs(props)

    const {
      reloadResponses,
      currentSurvey,
      persistedCpts,
      persistedResponses,
      prepareAll
    } = useSurveyPrep(
      surveyId,
      0 // props.type
    )

    const { cpt, cptSet, hasDiscreteScale, persistResponses } = useSurvey(
      store,
      currentUser,
      network,
      selectedVariable,
      currentSurvey,
      persistedCpts,
      persistedResponses,
      true
    )
    const { analyticsMap } = useAnalytics(network, allocations, persistedResponses, userMap)

    const publishAgg = async () => {
      if (network?.value && currentSurvey.value?.id && cptSet.value) {
        await persistSummaries(
          analyticsMap.value,
          network.value.id,
          currentSurvey.value.id,
          cptSet.value,
          !isNil(network.value?.fsNetworkId),
          isAnonymous.value
        )
      }
    }

    const calcProbs = async () => {
      if (network?.value && currentSurvey.value?.id && cptSet.value) {
        const probMap_ = await trace(
          analyticsMap.value,
          network.value.id,
          currentSurvey.value.id,
          cptSet.value,
          props.nodeIds,
          isAnonymous.value
        )
        probMap.value = probMap_?.traces
      }
    }

    onMounted(() => {
      refresh()
    })

    const refresh = async () => {
      if (network?.value) {
        await prepareAll()
        probMap.value = {}
        // calcProbs()
      }
    }

    const selectVariable = (variable: Variable) => {
      if (network?.value) {
        selectedVariable.value = variable
      }
    }

    watch(
      () => props.variable,
      () => {
        selectedVariable.value = props.variable
      }
    )

    watch(
      () => props.surveyId,
      () => {
        refresh()
      }
    )

    const isOperating = ref(false)

    const onResponsesSave = async (responses: Array<ResponseSchema>) => {
      isOperating.value = true
      try {
        await persistResponses(responses, true) // true for analytics
        await reloadResponses()
        message.success(MESSAGE.CPT_RESPONSES_SAVE_SUCCESS)
      } catch (err) {
        message.error(MESSAGE.CPT_RESPONSES_SAVE_FAIL)
        isOperating.value = false
        throw err
      }
      isOperating.value = false
    }

    const exportSurveyData = async () => {
      exportAnalytics(
        props.surveyId,
        network.value,
        allocations.value,
        userMap.value,
        cptSet.value,
        analyticsMap.value,
        isAnonymous.value,
        currentSurvey.value
      )
    }

    return {
      exportSurveyData,
      isAnonymous,
      DB_ENUM_VALUES,
      EVENTS,
      TABLE_EVENTS,
      cpt,
      cptSet,
      currentSurvey,
      hasDiscreteScale,
      isOperating,
      analyticsMap,
      publishAgg,
      calcProbs,
      refresh,
      selectVariable,
      SurveyExportFormatEnum,
      ROUTE_NAME,
      SIMPLE_EVENTS,
      probMap,
      persistedResponses,
      onResponsesSave,
      PROB_EVENTS,
      selectedVariable
    }
  }
})
</script>

<style lang="stylus">
@import '../../styles/Page.styl';

.sz-survey-ce
  position: absolute
  left: 0
  top: 0
  right: 0
  bottom: 0
  overflow: auto
  .sz-command-bar
    background-color: #ccdadf

.sz-survey-ce > div
  display: flex
  flex-direction: column
  flex: 1 1 auto
  padding-bottom: 20px
</style>
