import { computed, ref, watch } from 'vue'

import { DB_ENUM_VALUES } from '@/constants/database'
import { ModuleNames } from '@/constants/vuex'
import { CollectionMethodMap, CPTSet, Network, Variable } from '@/libs/bayes'
import { CPTMethod } from '@/libs/bayes/enums'
import { VueRef } from '@/libs/vue'
import { QuestionnaireActionEnum } from '@/store/enums/actions'
import { Store } from '@/store/types'
import { vuexActions } from '@/store/util'
import { CPTSchema, ResponseSchema, SurveySchema, User } from '@/types'

export default function useSurvey(
  store: Store,
  currentUser: VueRef<User>,
  network: VueRef<Network>,
  variable: VueRef<Variable>,
  currentSurvey: VueRef<SurveySchema>,
  persistedCpts: VueRef<CPTSchema[]>,
  persistedResponses: VueRef<ResponseSchema[]>,
  loadPersisted = false
): any {
  const hasDiscreteScale = computed(() => {
    if (!currentSurvey.value) {
      return false
    }
    const survey = currentSurvey.value as SurveySchema
    return survey?.scale?.method === DB_ENUM_VALUES.SURVEY.SCALE_METHOD.PHRASE
  })

  const cptSet = computed(() => {
    if (!currentSurvey.value || !network.value) {
      return
    }
    const cptSet_ = new CPTSet(
      network.value,
      currentSurvey?.value?.collectionMethod as CPTMethod,
      currentSurvey?.value?.ext?.collectionMethodMap as CollectionMethodMap
    )
    if (cptSet_) {
      cptSet_.generateCombinations()
    }
    if (loadPersisted) {
      if (persistedCpts.value) {
        cptSet_.load(persistedCpts.value as CPTSchema[])
      }
    }
    updateCPT()
    return cptSet_
  })

  const cpt = ref()

  watch(cptSet, () => {
    if (cptSet.value) {
      cptSet.value.generateCombinations()
      if (loadPersisted) {
        if (persistedCpts.value) {
          cptSet.value.load(persistedCpts.value as CPTSchema[])
        }
      }
      updateCPT()
    }
  })

  watch(variable, () => {
    updateCPT()
  })

  const updateCPT = () => {
    if (cptSet.value && variable.value) {
      cpt.value = cptSet.value.getCPT(variable.value)
    }
  }

  const persistResponses = async (responses: Array<ResponseSchema>, isAnalytics: boolean) => {
    const userId = currentUser.value?.id
    const surveyId = currentSurvey.value?.id
    const workspaceId = currentSurvey.value?.workspaceId
    const networkId = network.value?.id
    const cptId = cpt.value?.id
    if (userId && surveyId && workspaceId && cptId && networkId) {
      await store.dispatch(
        vuexActions(ModuleNames.QUESTIONNAIRE, QuestionnaireActionEnum.PATCH_RESPONSES),
        responses.map((response) =>
          Object.assign({}, response, { userId, surveyId, workspaceId, cptId, networkId })
        )
      )
      // If isAnalytics (SurveyAnalytics), return all responses. Otherwise (Questionnaire), return from specific user.
      await store.dispatch(
        vuexActions(ModuleNames.QUESTIONNAIRE, QuestionnaireActionEnum.GET_SURVEY_RESPONSES),
        isAnalytics ? { surveyId, networkId } : { surveyId, userId, networkId }
      )
    }
  }

  return {
    cpt,
    cptSet,
    hasDiscreteScale,
    persistResponses,
    updateCPT
  }
}
